import cn from 'classnames';

import {AlignmentEnum} from '@/enums';
import Col, {Start, Span} from '@/components/brochureV2/layout/Grid/Col';

interface ColTwoProps {
  className?: string;
  children: any;
  alignment?: AlignmentEnum;
}

/**
 * @deprecated
 */
const ColTwo = ({className, children, alignment}: ColTwoProps) => {
  const currentAlignment = alignment || AlignmentEnum.Left;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const mobileImageAlignment = currentAlignment === AlignmentEnum.Top;
  return (
    <Col
      start={{
        md: isLeft ? Start.Seven : Start.One,
      }}
      span={{
        default: Span.Twelve,
        md: Span.Six,
      }}
      className={cn(
        'flex',
        {
          'md:order-2': isLeft,
          'md:order-1': !isLeft,
          // Mobile alignment
          'order-1 mb-10': mobileImageAlignment,
        },
        className,
      )}
    >
      {children}
    </Col>
  );
};

export default ColTwo;
