import cn from 'classnames';

import {AlignmentEnum} from '@/enums';
import Eyebrow from '@/components/brochureV2/primitive/Eyebrow/Eyebrow';
import {H2Html, H3Html, DivHtml} from '@/components/shared/HtmlElements';
import Image from '@/components/shared/Image/Image';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
} from '@/components/brochureV2/primitive/Button/types';

import type {ProductDemo01Props} from './ProductDemo01';

type AllProductsDemoProps = ProductDemo01Props & {
  allProductsDemoClass?: string;
  customButtonTheme?: ButtonTheme;
};

/**
 * @deprecated
 */
export const AllProductsDemo = ({
  customButtonTheme = ButtonTheme.Light,
  card,
  kicker,
  heading,
  isLargeHeading = true,
  image,
  alignment,
  videoButton,
  allProductsDemoClass = 'bg-[#181818] text-white',
}: AllProductsDemoProps) => {
  if (!image?.srcSet) {
    return null;
  }
  const currentAlignment = alignment || AlignmentEnum.Left;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const {heading: subheading, contentHtml, link} = card;
  return (
    <div
      className={cn(
        'container flex flex-col h-full rounded-2xl overflow-hidden',
        {
          'sm:flex-row': isLeft,
          'sm:flex-row-reverse': !isLeft,
        },
        allProductsDemoClass,
      )}
    >
      <div
        className={cn(
          'py-8 px-[5%] w-full sm:w-3/4 sm:flex-row flex-col sm:basis-1/2 items-center flex sm:order-none md:text-t8',
          {
            'order-1': currentAlignment === AlignmentEnum.Top,
          },
        )}
      >
        <div className="mx-4">
          {kicker && <Eyebrow>{kicker}</Eyebrow>}
          {heading && (
            <H2Html className="font-bold text-t2 mb-2 sm:mb-4">
              {heading}
            </H2Html>
          )}
          {subheading && (
            <H3Html
              className={cn('font-bold mb-2 sm:mb-4', {
                'text-t3': isLargeHeading,
                'text-t4': !isLargeHeading,
              })}
            >
              {subheading}
            </H3Html>
          )}
          {contentHtml && (
            <DivHtml className="mb-6 text-base sm:mb-8 sm:text-lg">
              {contentHtml}
            </DivHtml>
          )}
          {link?.url && (
            <div className="mt-md sm:mt-3xl">
              <Button
                type="link"
                href={link.url}
                variant={ButtonVariant.Secondary}
                theme={customButtonTheme}
                componentName={link.name || ''}
                {...link}
              >
                {link.text}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className={cn('flex-col sm:basis-1/2 sm:flex-row', {
          'flex items-center justify-center': videoButton?.url,
        })}
      >
        <Image
          className="object-cover w-full h-full"
          sizes={image.sizes}
          srcSet={image.srcSet}
          alt={image.alt}
          loading="lazy"
        />
        {videoButton?.url && (
          <div className="absolute">
            <Button
              theme={ButtonTheme.Dark}
              type="link"
              href={videoButton?.url}
              componentName={videoButton.name ?? ''}
              className="flex justify-center items-center gap-2 rounded-full w-[100px] h-[100px] p-[32px]"
            >
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path
                  d="M34 17.5006C34 18.3302 33.5707 19.0963 32.8683 19.5206L9.535 33.6629C9.164 33.8869 8.74867 34 8.33333 34C7.93667 34 7.54 33.8986 7.183 33.6936C6.45267 33.274 6 32.4915 6 31.6429V3.35817C6 2.50962 6.45267 1.72708 7.183 1.30752C7.91333 0.885606 8.814 0.899749 9.535 1.33816L32.8683 15.4805C33.5707 15.9048 34 16.6709 34 17.5006Z"
                  fill="currentcolor"
                />
              </svg>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
