import {twMerge} from '@/stylesheets/twMerge';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {getButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Section from '@/components/brochureV2/primitive/Section/Section';

import variants from './variants';
import {ProductDemoVariant} from './types';
import type {ProductDemoProps} from './types';

export {ProductDemoVariant};

/**
 * @deprecated
 */
export default function ProductDemo({
  alignment,
  cards = [],
  className = '',
  headingClass,
  colorScheme,
  customButtonTheme,
  eyebrowClass,
  heading,
  image,
  imageClass,
  columnOneClass,
  columnTwoClass,
  isFullBleed = false,
  isLargeHeading,
  kicker,
  productDemoVariant,
  afterContent,
  videoButton,
  customInnerContainer,
  progressBarClass,
  mobileProgressBarClass,
  progressBarHoverClass,
  allProductsDemoClass,
  primaryButtonClass,
  arrowAnimation,
  ...props
}: ProductDemoProps) {
  if (!cards?.length) {
    return null;
  }
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const buttonTheme = customButtonTheme || getButtonTheme(currentScheme);
  const currentVariant =
    productDemoVariant ||
    (cards.length > 1 ? ProductDemoVariant.V02 : ProductDemoVariant.V01);
  const baseProps = {
    alignment: alignment || AlignmentEnum.Left,
    headingClass,
  };
  const variant01Props = {
    ...baseProps,
    card: cards[0],
    image,
    imageClass,
    buttonTheme,
    videoButton,
    columnOneClass,
    columnTwoClass,
    primaryButtonClass,
    arrowAnimation,
  };
  const variant02Props = {
    ...baseProps,
    progressBarClass,
    mobileProgressBarClass,
    progressBarHoverClass,
    cards,
    columnOneClass,
    columnTwoClass,
  };
  // We are collecting edge-case uses of the component here until we can determine how best to handle them
  const variant03Props = {
    ...variant01Props,
    eyebrowClass,
    heading,
    isFullBleed,
    isLargeHeading,
    kicker,
    afterContent,
    allProductsDemoClass,
  };

  const allProductsProps = {
    ...variant03Props,
    customButtonTheme,
  };

  const variantProps = {
    [ProductDemoVariant.V01]: variant01Props,
    [ProductDemoVariant.V02]: variant02Props,
    [ProductDemoVariant.V03]: variant03Props,
    [ProductDemoVariant.AllProducts]: allProductsProps,
  };
  const Component = customInnerContainer ?? variants[currentVariant];
  return (
    <Section
      sectionName="ProductDemo"
      {...props}
      className={twMerge(
        'py-16 md:py-20',
        sectionSchemeClasses[currentScheme],
        className,
      )}
    >
      <Component {...variantProps[currentVariant]} />
    </Section>
  );
}
