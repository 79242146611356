import type {VariantMap} from '@/types';

import {ProductDemoVariant} from '../types';

import ProductDemo01 from './ProductDemo01';
import ProductDemo02 from './ProductDemo02';
import {AllProductsDemo} from './AllProductsDemo';

const variants: VariantMap = {
  [ProductDemoVariant.AllProducts]: AllProductsDemo,
  [ProductDemoVariant.V01]: ProductDemo01,
  [ProductDemoVariant.V02]: ProductDemo02,
  [ProductDemoVariant.V03]: ProductDemo01,
};

export default variants;
