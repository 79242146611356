import cn from 'classnames';

import {AlignmentEnum} from '@/enums';
import Col, {Start, Span} from '@/components/brochureV2/layout/Grid/Col';

interface ColOneProps {
  className?: string;
  children: any;
  alignment?: AlignmentEnum;
}

/**
 * @deprecated
 */
const ColOne = ({className, children, alignment}: ColOneProps) => {
  const currentAlignment = alignment || AlignmentEnum.Left;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const mobileImageAlignment = currentAlignment === AlignmentEnum.Top;
  return (
    <Col
      start={{
        md: isLeft ? Start.One : Start.Eight,
      }}
      span={{
        default: Span.Twelve,
        md: Span.Five,
      }}
      className={cn(
        `flex flex-col`,
        {
          'md:order-1': isLeft,
          'md:order-2': !isLeft,
          // Mobile alignment
          'order-2': mobileImageAlignment,
        },
        className,
      )}
    >
      {children}
    </Col>
  );
};

export default ColOne;
