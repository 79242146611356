import cn from 'classnames';
import {useCallback, useEffect, useRef, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import {PHtml} from '@/components/shared/HtmlElements';
import Image from '@/components/shared/Image/Image';
import type {CardComponent} from '@/types';
import {AlignmentEnum} from '@/enums';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import {ColOne, ColTwo} from '../shared';
import type {ProductDemoProps} from '../types';

const TIME_BETWEEN_SLIDES = 5000;

/**
 * @deprecated
 */
export default function ProductDemo02({
  cards,
  alignment,
  progressBarClass = 'border-shopify-green-50',
  mobileProgressBarClass = 'bg-shopify-green-50',
  progressBarHoverClass = 'md:hover:border-shopify-green-50',
  columnOneClass,
  columnTwoClass,
}: Pick<
  ProductDemoProps,
  | 'cards'
  | 'alignment'
  | 'progressBarClass'
  | 'progressBarHoverClass'
  | 'mobileProgressBarClass'
  | 'columnOneClass'
  | 'columnTwoClass'
>) {
  const currentAlignment = alignment || AlignmentEnum.Left;
  const [hasIntersected, setHasIntersected] = useState(false);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(
    cards && cards.length > 1 ? -1 : 0,
  );
  const [activeIndicator, setActiveIndicator] = useState(
    cards && cards.length > 1 ? -1 : 0,
  );

  const scrollToImage = useCallback((index: number) => {
    const activeImgElement =
      carouselRef.current &&
      carouselRef.current.querySelectorAll(`.product-demo-image`)[index];

    if (activeImgElement) {
      (activeImgElement as HTMLImageElement).parentElement?.scrollTo({
        left: activeImgElement.getBoundingClientRect().width * index,
        behavior: 'smooth',
      });
      setActiveIndex(index);
    }
  }, []);

  useIntersectionObserver(
    carouselRef,
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          setHasIntersected(true);
        }
      });
    },
  );

  useEffect(() => {
    if (cards && cards.length > 1) {
      if (hasIntersected && !hasBeenClicked) {
        timeoutRef.current = setTimeout(
          () => {
            scrollToImage(cards ? (activeIndex + 1) % cards.length : 0);
          },
          activeIndex < 0 ? 100 : TIME_BETWEEN_SLIDES,
        );
        return () => clearTimeout(timeoutRef.current);
      }
    }
  }, [activeIndex, hasIntersected, cards, hasBeenClicked, scrollToImage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setActiveIndicator(
              [...entry.target.parentNode!.children].indexOf(entry.target),
            );
          }
        });
      },
      {
        threshold: 0.7,
      },
    );

    carouselRef.current &&
      carouselRef.current
        .querySelectorAll('.product-demo-image')
        .forEach((card) => {
          observer.observe(card);
        });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (hasBeenClicked) {
      scrollToImage(activeIndex);
    }
  }, [activeIndex, hasBeenClicked, scrollToImage]);

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <div ref={carouselRef}>
      <Grid>
        <ColOne
          alignment={currentAlignment}
          className={twMerge(
            cn({
              'justify-start': cards.length > 3,
              'justify-center': cards.length <= 3,
            }),
            columnOneClass,
          )}
        >
          <MobileButtons
            cards={cards}
            activeIndicator={activeIndicator}
            setActiveIndex={setActiveIndex}
            setHasBeenClicked={setHasBeenClicked}
            hasBeenClicked={hasBeenClicked}
            mobileProgressBarClass={mobileProgressBarClass}
          />
          <DesktopButtons
            cards={cards}
            activeIndicator={activeIndicator}
            hasBeenClicked={hasBeenClicked}
            setActiveIndex={setActiveIndex}
            setHasBeenClicked={setHasBeenClicked}
            progressBarClass={progressBarClass}
            progressBarHoverClass={progressBarHoverClass}
          />
        </ColOne>
        <ColTwo
          alignment={currentAlignment}
          className={twMerge('w-full overflow-y-hidden', columnTwoClass)}
        >
          <div
            className={cn(
              'no-scrollbar flex overflow-y-scroll snap-x snap-mandatory',
              {
                'items-start': cards.length > 3,
                'items-center': cards.length <= 3,
              },
            )}
            tabIndex={0}
          >
            {cards &&
              cards.map((carouselItem, index) => {
                return carouselItem.image && carouselItem.image.srcSet ? (
                  <Image
                    key={`${carouselItem.image.alt}-card-${index}`}
                    data-index={index}
                    className="product-demo-image ml-10 inline-block w-full snap-center transition-transform duration-500 ease-in-out last-of-type:mr-10"
                    alt={carouselItem.image.alt}
                    sizes={carouselItem.image.sizes}
                    srcSet={carouselItem.image.srcSet}
                    onTouchMove={() => {
                      setHasBeenClicked(true);
                    }}
                  />
                ) : null;
              })}
          </div>
        </ColTwo>
      </Grid>
    </div>
  );
}

const Card = ({card}: {card: CardComponent}) => (
  <>
    <h3 className="font-bold text-t5">{card.heading}</h3>
    <Spacer size="xs" />
    {card.contentHtml ? (
      <PHtml className="text-body-base">{card.contentHtml}</PHtml>
    ) : null}
  </>
);

const DesktopButtons = ({
  cards,
  activeIndicator,
  hasBeenClicked,
  setActiveIndex,
  setHasBeenClicked,
  progressBarClass,
  progressBarHoverClass,
}: {
  cards: CardComponent[];
  activeIndicator: number;
  hasBeenClicked: boolean;
  setActiveIndex: (index: number) => void;
  setHasBeenClicked: (clicked: boolean) => void;
  progressBarClass: string;
  progressBarHoverClass: string;
}) => {
  const cardClasses =
    'relative border-shade-30 md:pl-6  text-left transition-colors md:border-l-4 md:block';

  return (
    <>
      {cards.map((card, index) => (
        <div key={`${card.heading}-content`}>
          {cards && cards.length > 1 ? (
            <button
              className={cn(
                cardClasses,
                progressBarHoverClass,
                'cursor-default md:cursor-pointer md:hover:text-shade-70',
                {hidden: index !== activeIndicator},
              )}
              type="button"
              data-component-name={card.heading}
              onClick={() => {
                setActiveIndex(index);
                setHasBeenClicked(true);
              }}
            >
              <div
                className={cn(
                  progressBarClass,
                  'absolute inset-0 -left-1 z-10 ease-linear',
                  {
                    'md:bottom-0': index === activeIndicator,
                    'md:bottom-full': index !== activeIndicator,
                    'md:border-l-4': cards.length > 1,
                  },
                )}
                style={{
                  transitionDuration: `${
                    cards.length > 1 &&
                    index === activeIndicator &&
                    !hasBeenClicked
                      ? TIME_BETWEEN_SLIDES
                      : 0
                  }ms`,
                }}
              />
              <Card card={card} />
            </button>
          ) : (
            <div className={cardClasses}>
              <Card card={card} />
            </div>
          )}
          <Spacer desktop="40" />
        </div>
      ))}
    </>
  );
};

const MobileButtons = ({
  activeIndicator,
  setActiveIndex,
  setHasBeenClicked,
  hasBeenClicked,
  cards,
  mobileProgressBarClass,
}: {
  activeIndicator: number;
  setActiveIndex: (index: number) => void;
  setHasBeenClicked: (clicked: boolean) => void;
  hasBeenClicked: boolean;
  cards: CardComponent[];
  mobileProgressBarClass: string;
}) => (
  <div className="md:hidden">
    {cards &&
      cards.map((_, index: number) => {
        const progressBarClass =
          activeIndicator === index ? mobileProgressBarClass : 'bg-shade-30';
        return (
          <button
            tabIndex={-1}
            key={`mobile-button--${index}`}
            className="relative mt-5 mb-4 mr-4 max-w-[5.25rem] py-4"
            style={{
              width: `calc(${Math.floor((1 / cards.length) * 100)}% - 1rem)`,
            }}
            type="button"
            onClick={() => {
              setActiveIndex(index);
              setHasBeenClicked(true);
            }}
          >
            <div
              className={cn(
                progressBarClass,
                'absolute top-1/2 h-0.5 w-full -translate-y-1/2',
              )}
            />
            <div
              className={cn(
                'absolute w-full h-1 top-1/2 -translate-y-1/2 z-10 scale-x-0 origin-left',
                {
                  'scale-x-100': activeIndicator === index,
                },
              )}
              style={{
                transition: `transform ${
                  activeIndicator === index && !hasBeenClicked
                    ? TIME_BETWEEN_SLIDES
                    : 0
                }ms ease-out`,
              }}
            />
          </button>
        );
      })}
  </div>
);
