import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import type {ProductDemoSection} from '@/components/brochureV2/section/ProductDemo/types';
import type {CardComponent} from '@/types';
import {AlignmentEnum, ButtonSizeEnum} from '@/enums';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Link from '@/components/brochureV2/primitive/Link/Link';
import {H2Html, H3Html, DivHtml} from '@/components/shared/HtmlElements';
import Eyebrow from '@/components/brochureV2/primitive/Eyebrow/Eyebrow';
import Image from '@/components/shared/Image/Image';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';

import {ColOne, ColTwo} from '../shared';

export interface ProductDemo01Props extends ProductDemoSection {
  buttonTheme?: ButtonTheme;
  card: CardComponent;
  eyebrowClass?: string;
  headingClass?: string;
  imageClass?: string;
  columnOneClass?: string;
  columnTwoClass?: string;
  afterContent?: any;
  primaryButtonClass?: string;
  arrowAnimation?: boolean;
}

/**
 * @deprecated
 */
export default function ProductDemo01({
  alignment,
  buttonTheme = ButtonTheme.Dark,
  card,
  eyebrowClass,
  heading,
  image,
  imageClass,
  isLargeHeading,
  kicker,
  videoButton,
  headingClass,
  columnOneClass,
  columnTwoClass,
  afterContent,
  primaryButtonClass,
  arrowAnimation,
}: ProductDemo01Props) {
  const {heading: subheading, contentHtml, link} = card;
  const currentAligmnent = alignment || AlignmentEnum.Left;

  return (
    <Grid>
      <ColOne
        alignment={currentAligmnent}
        className={twMerge('justify-center mb-10 md:mb-0 ', columnOneClass)}
      >
        {kicker && <Eyebrow className={eyebrowClass}>{kicker}</Eyebrow>}
        {heading && (
          <H2Html
            className={cn(headingClass, 'font-bold text-t2 mb-2 md:mb-4')}
          >
            {heading}
          </H2Html>
        )}
        {subheading && (
          <H3Html
            className={cn('font-bold mb-2 md:mb-4', {
              [headingClass as string]: !isLargeHeading,
              'text-t3': isLargeHeading,
              'text-t5': !isLargeHeading,
            })}
          >
            {subheading}
          </H3Html>
        )}
        {contentHtml && (
          <DivHtml className="text-base md:text-lg">{contentHtml}</DivHtml>
        )}
        {link?.url && (
          <Link
            href={link.url}
            theme={buttonTheme}
            size={ButtonSizeEnum.Medium}
            componentName={link.name || ''}
            className={cn('mt-6 md:mt-8', primaryButtonClass)}
            arrowAnimation={arrowAnimation}
          >
            {link.text}
          </Link>
        )}
        {afterContent || null}
      </ColOne>
      <ColTwo
        alignment={currentAligmnent}
        className={twMerge(
          cn('items-center', {
            'justify-center': videoButton?.url,
          }),
          columnTwoClass,
        )}
      >
        {image?.srcSet && (
          <Image
            className={cn(imageClass, {
              'm-auto': currentAligmnent === AlignmentEnum.Right,
            })}
            sizes={image.sizes}
            srcSet={image.srcSet}
            alt={image.alt}
            loading="lazy"
          />
        )}
        {videoButton?.url && (
          <div className="absolute">
            <Button
              theme={ButtonTheme.Light}
              type="link"
              href={videoButton?.url}
              componentName={videoButton.name ?? ''}
              className="flex items-center gap-2"
            >
              {videoButton?.text}
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                  d="M16.5 10.0003C16.5 10.3523 16.316 10.6773 16.015 10.8573L6.015 16.8573C5.856 16.9523 5.678 17.0003 5.5 17.0003C5.33 17.0003 5.16 16.9573 5.007 16.8703C4.694 16.6923 4.5 16.3603 4.5 16.0003V4.00035C4.5 3.64035 4.694 3.30835 5.007 3.13035C5.32 2.95135 5.706 2.95735 6.015 3.14335L16.015 9.14335C16.316 9.32335 16.5 9.64835 16.5 10.0003Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
          </div>
        )}
      </ColTwo>
    </Grid>
  );
}
